<template>
  <ValidationProvider tag="div" class="column is-half" :rules="validationRules" v-slot="{ errors, valid }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
          <b-numberinput
              :value="value[`${question.qid}`]"
              @input="$emit('input', { [`${question.qid}`]: $event })"
              :controls="false"
              :disabled="loading"
          ></b-numberinput>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>

import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'NumberInput',
  mixins: [QuestionMixin]
}
</script>
